import React from 'react';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <div class="center h-90 dark-bg">
        <h1>Hello World.</h1>
      </div>
      <Footer />
    </div>
  );
}

export default App;
