import React from 'react';

const Footer = () => {
    return (
      <footer>
        <p>Undergoing redesign. Stay tuned.</p>
      </footer>
    );
};

export default Footer;
